import Vue from 'vue';
import InspectionsList from '../../../../../components/inspections/list/InspectionsList.vue';

Vue.directive('color', function (/*el, binding, vnode*/) {
	//el.style.color = 'purple';
	//el.style.backgroundColor = binding.value.toString();
	// console.log('el:', el);
	// console.log('binding.value:', binding.value);
	// console.log('vnode:', vnode);
});

//console.log('v:', Vue.directives);
export default {
	name: 'InspectionList',
	components: { InspectionsList },
};
