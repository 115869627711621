import { mapState } from 'vuex';
import ValidationAlert from '@/components/global/ValidationAlert.vue';

export default {
	props: ['dialog', 'isLoadingAssign', 'error', 'isLoadingAssigned'],
	data() {
		return {
			asignations: [],
		};
	},
	created() {},
	mounted() {},
	computed: {
		...mapState('security', ['usersActive']),
		...mapState('inspectionAsign', ['inspectionAsigns']),
	},
	watch: {
		inspectionAsigns: function () {
			this.asignations = [];
			this.inspectionAsigns.map((asign) => {
				if (!this.asignations.includes(asign.user_id)) {
					this.asignations.push(asign.user_id);
				}
			});
		},
	},
	methods: {
		closeDialog() {
			this.$emit('close', false, null);
		},
		save() {
			this.$emit('close', false, this.asignations);
		},
	},
	components: {
		ValidationAlert,
	},
};
