import { $EventBus } from '@/main';
import { mapActions, mapState } from 'vuex';
import InspectionAsign from './asign/InspectionAsign.vue';
import textTransform from '@/helpers/textTransform.js';

export default {
	name: 'Inspections',
	data: () => ({
		filters: {
			search: '',
			subprocess_id: null,
		},
		headers: [
			{
				text: 'Nombre',
				value: 'name',
				sortable: false,
				align: 'null',
				class: 'primary--text text-center',
			},
			{
				text: 'Código',
				value: 'code',
				sortable: false,
				align: 'null',
				class: 'primary--text text-center',
			},
			{
				text: 'Revisión',
				value: 'revision',
				sortable: false,
				align: 'null',
				class: 'primary--text text-center',
			},
			{
				text: 'Versión',
				value: 'version',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Subproceso',
				value: 'subprocess',
				sortable: false,
				align: 'null',
				class: 'primary--text text-center',
			},

			{
				text: 'Registros',
				value: 'registers',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: '',
				align: 'end',
				value: 'new',
				sortable: false,
				class: 'primary--text',
			},
		],
		companies: [],
		editedIndex: -1,
		editedItem: {
			id: 0,
			name: '',
			code: '',
			records: 0,
		},
		deleteIndex: -1,
		deleteItemCompany: {
			id: 0,
			name: '',
			code: '',
			records: 0,
		},
		defaultItem: {
			id: 0,
			name: '',
			code: '',
			records: 0,
		},
		loading: false,
		dialog: false,
		userActive: null,
		inspectionId: null,
		isLoadingAssign: false,
		isLoadingAssigned: false,
		errorAssign: [],
	}),
	created() {},
	mounted: function () {
		$EventBus.$emit('showSubNav', true);
		this.getDataSubprocess();
	},
	methods: {
		...mapActions('inspection', ['list']),
		...mapActions('security', ['getUsersActive']),
		...mapActions('subprocess', { getSubprocess: 'list' }),
		...mapActions('inspectionAsign', ['findByInspection', 'saveAsign']),

		textTransform,

		initialize() {
			this.getUsersActiveSecurity();
		},
		getData() {
			this.loading = true;
			let filters = { ...this.filters };
			if ((filters?.search || '').length < 2) {
				delete filters.search;
			}

			this.list({
				success: () => {
					this.loading = false;
				},
				fail: () => {
					this.loading = false;
				},
				params: filters,
			});
		},
		getDataSubprocess() {
			this.getSubprocess({
				success: () => {},
				fail: () => {},
				query: '',
			});
		},
		listenTextSearch() {
			if ((this.filters.search || '').length > 1 || this.filters.search == '') {
				this.getData();
			}
		},
		changeSearch() {
			this.getData();
		},
		formatDateString(date) {
			let dateString = '';
			if (date) {
				let d = date.toString().substring(0, 10).split('-');
				dateString = `${d[2]}-${d[1]}-${d[0]}`;
			}
			return dateString;
		},
		getUsersActiveSecurity() {
			this.getUsersActive({
				success: () => {},
				fail: () => {},
			});
		},
		getInspectionAsigns() {
			this.isLoadingAssigned = true;
			this.findByInspection({
				success: () => {
					this.isLoadingAssigned = false;
				},
				fail: () => {
					this.isLoadingAssigned = false;
				},
				id: this.inspectionId,
			});
		},
		asignInspectionUser(inspectionId) {
			this.inspectionId = inspectionId;
			this.getUsersActiveSecurity();
			this.getInspectionAsigns();
			this.dialog = true;
		},
		closeAsign(status, asignations) {
			if (asignations) {
				if (asignations.length > 0) {
					this.storeAsign(asignations);
				}
			} else {
				this.dialog = status;
				this.errorAssign = [];
			}
		},
		groupDataAsign(asignations) {
			let asigns = [];
			asignations.forEach((userId) => {
				this.usersActive.forEach((userActive) => {
					if (userActive.id === userId) {
						asigns.push({
							user_names: userActive.name,
							user_id: userActive.id,
							inspection_id: this.inspectionId,
						});
					}
				});
			});
			return asigns;
		},
		storeAsign(asignations) {
			this.isLoadingAssign = true;
			this.saveAsign({
				success: () => {
					this.isLoadingAssign = false;
					this.dialog = false;
					this.inspectionId = null;
					this.errorAssign = [];
					$EventBus.$emit(
						'showSnack',
						'success',
						'Asigación realizada satisfactoriamente.'
					);
				},
				fail: (error) => {
					this.isLoadingAssign = false;
					if (Array.isArray(error?.response?.data?.message)) {
						this.errorAssign = error.response.data.message;
					} else {
						this.errorAssign = [
							'Algo salio mal, no se guardo la información',
						];
					}
					$EventBus.$emit(
						'showSnack',
						'error',
						'Algo salio mal, no se guardo la información'
					);
				},
				data: { asignations: this.groupDataAsign(asignations) },
			});
		},
	},
	components: { InspectionAsign },
	computed: {
		...mapState('inspection', ['inspections']),
		...mapState('subprocess', ['subprocesses']),
		...mapState('security', ['usersActive']),
		...mapState('inspectionAsign', ['inspectionAsigns']),
	},
	watch: {
		$route: {
			handler: function (value) {
				if (value.name == 'InspectionsList') {
					this.getData();
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
