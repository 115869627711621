var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"hidden-md-and-up"},[_c('h1',{staticClass:"title font-weight-medium"},[_vm._v("Inspencciones")])])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar inspección","single-line":"","hide-details":"","solo":""},on:{"input":function($event){return _vm.listenTextSearch()}},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":[{ id: null, name: 'Todos los subprocesos' }].concat( _vm.subprocesses ),"label":"Todos los subprocesos","item-text":"name","item-value":"id","single-line":"","hide-details":"","solo":""},on:{"input":_vm.changeSearch},model:{value:(_vm.filters.subprocess_id),callback:function ($$v) {_vm.$set(_vm.filters, "subprocess_id", $$v)},expression:"filters.subprocess_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.inspections,"loading":_vm.loading,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.textTransform(item.name))+" ")]}},{key:"item.revision",fn:function(ref){
				var item = ref.item;
return [_c('span',{staticClass:"grey--text",domProps:{"textContent":_vm._s(
								item.revision ? _vm.formatDateString(item.revision) : ''
							)}})]}},{key:"item.subprocess",fn:function(ref){
							var item = ref.item;
return [_c('span',{staticClass:"grey--text",domProps:{"textContent":_vm._s(item.subprocess_name)}})]}},{key:"item.registers",fn:function(ref){
							var item = ref.item;
return [_c('span',{staticClass:"grey--text",domProps:{"textContent":_vm._s(item.registers_count)}})]}},{key:"item.new",fn:function(ref){
							var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('validate-activity',{attrs:{"module":"inspeccion","page":"mostrarReporte","activity":"mostrarReporte"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var isDisabled = ref.isDisabled;
return [(item.flag_report)?_c('v-btn',{attrs:{"to":{
										name: 'InspectionReport',
										params: { id: item.format },
									},"disabled":isDisabled,"color":"default","icon":"","link":""}},[_c('v-icon',[_vm._v("mdi-finance")])],1):_vm._e()]}}],null,true)}),_c('validate-activity',{attrs:{"module":"inspeccion","page":"listaInspecciones","activity":"asignarRevision"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var isDisabled = ref.isDisabled;
return [_c('v-btn',{attrs:{"disabled":isDisabled,"color":"default","icon":""},on:{"click":function($event){return _vm.asignInspectionUser(item.id)}}},[_c('v-icon',[_vm._v("mdi-account-cog ")])],1)]}}],null,true)}),_c('validate-activity',{attrs:{"module":"inspeccion","page":"listaRegistros","activity":"listarRegistros"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var isDisabled = ref.isDisabled;
return [_c('v-btn',{staticClass:"text-none",attrs:{"disabled":isDisabled,"to":{
										name: 'InspectionsRegisterList',
										params: { id: item.format },
									},"color":"primary","text":"","link":""}},[_vm._v(" Ver "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-chevron-right ")])],1)]}}],null,true)})],1)]}}],null,true)})],1)],1)],1),_c('inspection-asign',{attrs:{"dialog":_vm.dialog,"error":_vm.errorAssign,"isLoadingAssign":_vm.isLoadingAssign,"isLoadingAssigned":_vm.isLoadingAssigned},on:{"close":_vm.closeAsign}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }